@tailwind base;
@tailwind components;
@tailwind utilities;

div[kendowatermarkoverlay] {
  display:none !important
}
 /*

//width 
::-webkit-scrollbar {
  width: 10px;
}

// Track 
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

// Handle 
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

// Handle on hover 
::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/

@layer components {
  .tw-hidden {
    display: none;
  }
}

@layer base {
 

  .main-background{ 
    @apply   bg-white dark:bg-oscuro;  
  } 
 
  .sub-title-info {
    @apply text-lg text-gray-500 min-h-[31px] dark:text-white ;
  }
  .popup-icon{
    @apply cursor-pointer text-lg mt-1 mr-2 mb-1 ml-1 text-primary min-h-[31px] dark:text-blue-500 ;
  } 
  .button-popup-icon{
    @apply cursor-pointer text-base   text-primary  dark:text-blue-500 ;
  }
}

 
.main-background-login::after { 
  background-image: none;
  background-color: white !important;
}

  
.main-background-no-login::after { 
  background-image: url("/assets/images/backgrounds/1.jpg") ;  
  
}
.main-background-no-login{
  background-color: transparent !important ;
}
  

:root {
  --primary-color: #01579b;
  --error-color: #ff5722;
  --background-color: orange;
  --text-color: #2d2d2d;

  --alpha: 0.5;
 
}

input {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system",
    BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.right-spacer {
  flex: 1 1 auto;
}

.text-align-center {
  text-align: center;
}

.hit {
  font-size: 12px;
  color: #4caf50;
}

 
.color-warning {
  color: #ff5722 !important;
}

 
.color-disabled {
  color: #d7d5d5 !important;
}

 

.background-color-primary-05 {
  background-color: rgb(1, 87, 155, 0.05) !important;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-10 {
  width: 10%;
}

.width-5 {
  width: 5%;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-right-20 {
  margin-right: 20px;
}

.color-green {
  color: #4caf50;
}

/*FACTURACION*/
//paciente

  
 
.color-white {
  color: white !important;
}

.full-width {
  width: 100%;
}
 

.underline:hover {
  text-decoration: underline;
}

.c5e-calendar {
  width: calc(100% - 32px) !important;
}

.f7e-form-label {
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 13px;
  padding-bottom: 8px;
}

.form-field[type="text"] {
  height: 32px;
}

.single-row-input .form-label {
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  padding-bottom: 4px;
}



input,
textarea,
select {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 13.3333px Arial;
}

 

/* input tegett */

.tegett-input {
  background-color: white;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  outline: 0;
  padding: 8px !important;
  transition: box-shadow 0.15s;
  vertical-align: middle;
  //-webkit-appearance: none;
  box-shadow: 0 -2px 0 transparent inset, 0 0 0 1px #e0e0e0 inset;
  width: 390px;
  min-height: 32px;
  max-height: 32px;
}

 
 

//--
.submit-error {
  border-bottom: 2px solid red !important;
}

//Remover color focus
textarea:focus,
input:focus {
  outline: none;
}

.ui-dialog-buttonpane {
  text-align: right;
  border-width: 1px 0 0 0;
  background-image: none;
  padding: 0.3em 1em 0.5em 0.4em;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
}

/*Forms*/
/*.main {*/
  /* margin: 10px;
  max-width: 100%;*/
  /*padding-bottom: 10px !important;*/
/*}*/

/* migrado
.mainPage {
  margin: 10px;
  max-width: 100%;
  padding-bottom: 10px !important;
}*/

.link {
  cursor: pointer;
}

.text-link{
  text-decoration: none;
  cursor: pointer;
}

 
.activo {
  border: 1px solid green;
  background-color: #00800029;
}

.inactivo {
  border: 1px solid gray;
  background-color: #0000ff1a;
}

.estado {
  border-radius: 5px;
  padding: 0 4px;
  width: 60%;
  text-align: center;
}

.estadoListado {
  border-radius: 5px;
  padding: 0 4px;
  width: 100%;
  text-align: center;
}

.link-primary:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.no-hover {
  text-decoration: none !important;
}

.bold {
  font-weight: bold;
}


//------------------------------------------------------------- organización --------------------------------

/*Formularios*/

//asignar color dinamico
.set-color {
  color: var(--color);
}

.set-color-rgb {
  color: rgb(var(--color));
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 4px 12px;
  cursor: pointer;
  background-color: white;
}

FIELDSET {
  margin: 8px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
}

LEGEND {
  padding: 2px;
}

@media (max-width: 1200px) {
  .estado {
    width: 70%;
  }
}

@media (max-width: 1160px) {
  .estado {
    width: 80%;
  }
}

//v3 search

.icon-color-administracion {
  color: #7c3697 !important;
}

.icon-color-facturacion {
  color: #463191 !important;
  font-size: 18px;
}

//separadores de secciones de formularios
/*.border-color-primary-separator {
  border-bottom: 1px solid rgba(var(--color), var(--alpha));
  border-top: 1px solid rgba(var(--color), var(--alpha));
}
*/
.item-state-circle {
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  width: 8px;
  min-width: 8px;
  height: 8px;
  margin-right: 5px;
}

.t-row>* {
  margin-right: 8px;
}

.t-nivel-color-verde-sin-valorizacion {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0.3);
}

.t-nivel-color-azul {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  //background-color: rgb(3, 48, 227);
  background-color: #1c8def;
  border-color: rgba(5, 45, 132, 0.3);
}

.t-nivel-color-verde {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  //background-color: rgb(24, 204, 0);
  background-color: #6bba70;
  border-color: rgba(0, 204, 153, 0.3);
}

.t-nivel-color-amarillo {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  //background-color: rgb(236, 244, 10);
  background-color: #fcc614;
  border-color: rgba(119, 129, 3, 0.3);
}

.t-nivel-color-naranja {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  //background-color: rgb(235, 141, 0);
  background-color: #ff670f;
  border-color: rgba(150, 99, 4, 0.3);
}

.t-nivel-color-rojo {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  //background-color: rgb(194, 31, 31);
  background-color: #cc0000;
  border-color: rgba(150, 3, 3, 0.3);
}

.t-color-activo {
  /*background-color: rgb(0, 122, 204);
  border-color: rgba(0, 122, 204, 0.3);*/
  background-color: rgb(24, 204, 0);
  border-color: rgba(0, 204, 153, 0.3);
}

.t-color-inactivo {
  /*background-color: rgb(246, 157, 39);
  border-color: rgba(248, 248, 11, 0.3);*/
  background-color: rgb(235, 235, 235);
  border-color: rgba(189, 189, 187, 0.3);
}

.t-color-ninguno {
  background-color: rgba(61, 61, 61, 0.431);
  border-color: rgba(213, 213, 213, 0.3);
}

.t-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}

.with-100 {
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/*input[type="number"] {
  -moz-appearance: textfield;
}*/

.max-width-100 {
  max-width: 100px;
}

.max-width-150 {
  max-width: 150px;
}


.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 250px;
}

.border-container {
  border: 1px dashed;
  border-color: rgba(200, 200, 200, 1);
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: white;
}

.k-dialog-buttongroup {
  justify-content: flex-end;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/*Margin*/
@for $i from 1 through 40 {
  .splus-margin-#{$i} {
    margin: 1px * $i;
  }
}

@for $i from 1 through 40 {

  @each $margin in top,
  left,
  bottom,
  right {
    .splus-margin-#{$margin}-#{$i} {
      margin-#{$margin}: 1px * $i;
    }
  }
}

/*Padding*/
@for $i from 1 through 40 {
  .splus-padding-#{$i} {
    padding: 1px * $i
  }
}

@for $i from 1 through 40 {

  @each $padding in top,
  left,
  bottom,
  right {
    .splus-padding-#{$padding}-#{$i} {
      padding-#{$padding}: 1px * $i;
    }

  }
}


.size-icon-standard {
  font-size: 16px;
}

//temporal pasar a la libreria
.layout-row {
  align-items: flex-start !important;
}

.layout-column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: flex-start;

  >div {
    display: flex;
  }
}
 
.splus-display-block {
  display: block !important;
}

.splus-width-standard-field {
  max-width: 250px;
}

.splus-width-standard-field-view {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px 10px;
  gap: 10px 10px;
  color: black;
}

.gray-scale {
  filter: grayscale(0%);
  animation: grayscale-animation 5s ease forwards;
  -webkit-animation: grayscale-animation 5s ease forwards;
}

@keyframes grayscale-animation {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(100%);
  }
}



/*Notificaciones*/
 /*
.k-notification-container { 
  border-radius: 6px;
  margin: 0px 20px 10px 0px !important;
*/
  
   
//}



/*Inicio barras loading*/

.lnsExpressionRenderer {
  scrollbar-color: rgba(105,112,125,0.5) rgba(0,0,0,0);
  scrollbar-width: thin;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}
.lnsExpressionRenderer {
  scrollbar-color: rgba(105,112,125,0.5) rgba(0,0,0,0);
  scrollbar-width: thin;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}

.loading-card-container {
  overflow: hidden;
  display: inline-flex;
  height: 24px;
  gap: 2px;
}



@keyframes animation-barras {
  0% {
      transform: translateY(0px);
  }

  50% {
      transform: translateY(66%);
  }

  100% {
      transform: translateY(0px);
  }
}


.css-uno-barra-loading-l {
  height: 100%;
  display: inline-block;
  width: 4px;
  margin-block-end: 12px;
  background-color: rgb(211, 218, 230);
  transform: translateY(0%);
}


.css-dos-barra-loading-l {
  height: 100%;
  display: inline-block;
  width: 4px;
  margin-block-end: 12px;
  background-color: rgb(203, 209, 221);
  transform: translateY(22%);
} 


.css-tres-barra-loading-l {
  height: 100%;
  display: inline-block;
  width: 4px;
  margin-block-end: 12px;
  background-color: rgb(194, 201, 212);
  transform: translateY(44%);
}



.css-cuatro-barra-loading-l {
  height: 100%;
  display: inline-block;
  width: 4px;
  margin-block-end: 12px;
  background-color: rgb(186, 192, 202);
  transform: translateY(66%);
}


.css-uno-barra-loading-l {
  animation: 1s ease 0s infinite normal none running animation-barras;
}

.css-dos-barra-loading-l {
  animation: 1s ease 0.1s infinite normal none running animation-barras;
}

.css-tres-barra-loading-l {
  animation: 1s ease 0.2s infinite normal none running animation-barras;
}

.css-cuatro-barra-loading-l {
  animation: 1s ease 0.3s infinite normal none running animation-barras;
}


.css-uno-barra-loading-l {
  animation: 1s ease 0s infinite normal none running animation-barras;
}

/*Fin barras loading*/