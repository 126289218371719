.mainPage {
  margin: 10px;
  max-width: 100%;
  padding-bottom: 10px !important;
}

/*-Forms Action*/
.ctrl-right > div > div > a {
  padding-right: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.ctrl-right > div > div > a:hover {
  text-decoration: underline;
}

/*Separadores*/
.body-fields .separator-field:not(:first-child) {
  margin-top: 30px;
}

 
.main-fields {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px 10px; 
  align-items: start;
}

.layout-simple {
  padding: 10px;
}

.layout-simple > div {
  /*padding: 10px;*/
  margin-top: 10px;
}

.layout-row {
 
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  splus-field {
    flex: 1;
  }
  splus-field div {
    padding: 2px 0;
  }
  .splus-width-10 {
    width: 10%;
    flex: inherit;
  }
  .splus-width-20 {
    width: 20%;
    flex: inherit;
  }
  .splus-width-30 {
    width: 30%;
    flex: inherit;
  }
  .splus-width-40 {
    width: 40%;
    flex: inherit;
  }
  .splus-width-50 {
    width: 50%;
    flex: inherit;
  }
  .splus-width-60 {
    width: 60%;
    flex: inherit;
  }
  .splus-width-70 {
    width: 70%;
    flex: inherit;
  }
  .splus-width-80 {
    width: 80%;
    flex: inherit;
  }
  .splus-width-90 {
    width: 90%;
    flex: inherit;
  }
  .splus-width-100 {
    width: 100%;
    flex: inherit;
  }
}

/*alineacion a la derecha*/
.right-spacer {
  flex: 1 1 auto;
}

//Espacio entre los botones de las paginas en el encabezado
.header-sub-toolbar > div > button {
  margin-right: 20px;
}

.header-sub-toolbar-buttons > button {
  margin-right: 20px;
  min-width: 80px;
}

//Espacio entre los botones de las grillas en el encabezado
.header-sub-toolbar-two > div > button {
  margin-right: 20px;
}
//Espacio entre el ultimo boton de las grillas
.header-sub-toolbar-two > div > button:last-child {
  margin-left: 20px;
}

.field-hidden { 
  display: none !important;
}

.field-visible {
  display: inherit;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.field-view-mode {
  box-shadow: none !important;
  padding: 0 !important;
}
.splus-input-container {
  padding-bottom: 4px;
  width: 90%;
  max-width: 250px;
}

.with-100percent {
  width: 100% !important;
}

//error en los campos de texto y select
.field-error,
.field-error .k-dropdown-wrap {
  box-shadow: 0 -2px 0 rgb(255, 0, 0, 0.6) inset, 0 0 0 1px #e0e0e000 inset !important;
 /* border-color: transparent !important;*/
}


.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  border-radius: 0px !important;
}

 

/*KENDO REEMPLAZAR*/
.k-checkbox {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.font-size-checkbox {
  font-size: 19px;
}

.k-button-link:hover,
.k-button-link.k-hover {
  text-decoration: none;
}

.k-button-link:hover {
  .k-button-icon {
    text-transform: none !important;
  }
}
 

.c5e-input-container {
  padding-bottom: 4px;
  width: 90%;
  max-width: 250px;
}

.c5e-input-container * ~ div[ng-messages] div[ng-message],
.c5e-input-error-text {
  color: #ff5252;
  font-size: 11px;
  line-height: 16px;
  padding-right: 20px;
  position: relative;
  top: 4px;
}

.splus-input {
  padding-bottom: 4px;
  width: 90%;
  max-width: 250px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .c5e-input-container {
    max-width: 95%;
  }

  .single-row-input {
    flex-direction: column !important;
  }

  .c5e-input-container-column {
    align-items: center !important;
    max-width: inherit !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

 